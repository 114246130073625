@media screen and (min-width: 1600px) {
  .contact_page{
    margin: 0px 10vw;
  }

}




@media screen and (max-width: 1000px) {
  .nav__item {
    font-size: 16px;
  }
  .active_nav {
    font-size: 16px;
  }
}



@media screen and (max-width: 800px) {
  .main_page_container {
    height: auto;
    margin-top: 55px;
  }

  .dasboard_page_container {
    height: auto;
    margin-top: 55px;
  }
  .timeseries_heading p{
    text-align: center;
    margin: 5px 0px;
    font-size: 12px;
  }

  .map_layer_loader_container_mobile {
    display: block;
    width: 100%;
    height: calc(100vh -55px);
    position: fixed;
    /* background-color: white; */
    border-radius: 10px;
    top: 55px;
    bottom: 0px;
    z-index: 1000;
  }
  .map_layer_loader_container_desktop {
    display: none;
  }

  .main_preloader {
    height: calc(100vh -55px);
    width: 100%;
    top: 55px;
  }
  .main_dashboard {
    width: 100%;
    height: 100%;
    display: block;
  }

  .left_panel {
    width: 100vw;
    padding: 10px;
    height: auto;
  }
  .right_panel {
    width: 100vw;
    height: 90vh;
  }
  .coordinates_container {
    display: none;
  }
  .logo_text h1 {
    color: black;
    text-shadow: 3px 3px 3px rgba(99, 99, 99, 0.5);
    font-size: 24px;
  }
  .leaflet-top,
  .leaflet-bottom {
    z-index: 1000000 !important;
  }

  .short_nav_container {
    justify-content: space-between;
    background-color: #ffffff;
  }

  .main_nav_logo {
    width: 180px;
  }

  .navbar__logo {
    display: block;
  }

  .navbar__toggle {
    display: block;
  }

  .navbar_container {
    height: 0px;
    padding: 0px 10px;
    justify-content: space-between;
    width: 100vw;
    background-color: white;
  }

  .main_nav {
    position: fixed;
    top: 55px;
    left: -100%;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    padding: 20px 0;
    z-index: 10;
    transition: 0.5s;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgb(200, 200, 200);
    z-index: 10000000000 !important;
  }

  .nav__content {
    /* padding: 0px 20px; */
    display: block;
    z-index: 10000000000 !important;
  }

  .show {
    left: 0;
  }

  .navbar__logo {
    width: 200px;
    /* margin-left: 10px; */
  }

  .navbar__logo img {
    width: 100%;
    height: 100%;
  }

  .nav__list {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 20px;
  }

  .nav__item {
    width: 100%;
    padding: 15px 0px;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(223, 223, 223);
    /* border-bottom   : 1px solid rgb(223, 223, 223); */
  }

  .active_nav {
    width: 100%;
    padding: 15px 0px;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(223, 223, 223);
    background-color: white;
    /* border-bottom   : 1px solid rgb(223, 223, 223); */
  }

  .nav__list a {
    color: black;
  }

  .dropdown_nav_container {
    width: 100%;
  }

  .site-button,
  .site-button-secondry {
    width: 100% !important;
  }

  .dropdown_content {
    display: none;
    position: relative;
    top: 0px;
    z-index: 10;
    background-color: rgb(245, 245, 245);
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 3px 3px 0px 0px;
    border-top: none;
    width: 100%;
    padding: 10px 0px;
  }

  .legend-panel-desktop {
    display: none;
  }
  .legend-panel-mobile {
    display: block;
    width: 100%;
    padding: 5px;
  }
  .legend-color-value {
    font-size: 12px;
  }

  .drought_details_container_desktop {
    display: none;
  }

  .drought_details_container_mobile {
    display: block;
    padding: 10px;
    width: 100%;
  }

  .drought_details_container_mobile p {
    font-size: 16px;
  }
  .zoom_btn {
    display: none;
  }

  .card_item {
    max-width: 100%;
  }
  .card_content {
    background-color: #c99000;
    padding: 10px;
  }
  .card_content p {
    font-size: 18px;
    color: white;
  }

  .para_content {
    margin-top: 20px;
    padding: 10px 10px;
    margin-bottom: 20px;
  }
  .para_content p {
    font-size: 18px;
  }
  .para_content ul {
    font-size: 18px;
  }
  .para_content h1 {
    font-size: 28px;
  }
  .card_btn {
    width: 100%;
    display: block;
  }

  .card_btn button {
    width: 100%;
    padding: 15px 15px;
    font-size: 18px;
  }
}
